import { Edit2, Check } from 'react-feather';
import { useState, useRef, useEffect } from 'react';
import { atou } from '../../utils/api';
import { JSONB } from '../../utils/jsonb';
import './DataTableCell.scss';

interface DataTableCellProps {
  value: string | {[key: string]: any} | null;
  onChange?: (newValue: string | null) => void;
  readonly?: boolean;
  isIndex?: boolean;
  rowIndex?: number;
  columnIndex?: number;
  isActive?: boolean;
  onActivate?: (cell: {rowIndex: number, columnIndex: number} | null) => void;
  onNextCell?: (rowIndex: number, columnIndex: number) => void;
}

export function DataTableCell({ 
  value, 
  onChange, 
  readonly = false,
  isIndex = false,
  rowIndex,
  columnIndex,
  isActive,
  onActivate,
  onNextCell
}: DataTableCellProps) {

  let displayValue: string = value + '';
  if (value && typeof value === 'object') {
    if (Object.keys(value).length === 1 && value._base64) {
      try {
        displayValue = JSONB.parseBase64(value._base64);
        displayValue = JSON.stringify(displayValue);
      } catch (e) {
        console.error('Error parsing JSONB', e);
        displayValue = atou(value._base64);
      }
    } else {
      displayValue = JSON.stringify(value);
    }
  }

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState<string | null>(value === null ? '' : displayValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  // Reset editValue when value changes from parent
  useEffect(() => {
    if (!isEditing) {
      setEditValue(value === null ? '' : displayValue);
    }
  }, [value, isEditing]);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.style.height = '0';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
      textareaRef.current.focus();
      textareaRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    // Only enter edit mode if cell becomes active and we're not already editing
    if (isActive && !isEditing && !readonly) {
      setIsEditing(true);
    }
    // If cell becomes inactive, exit edit mode
    if (!isActive && isEditing) {
      setIsEditing(false);
      setEditValue(displayValue);
    }
  }, [isActive]);

  const handleNullSave = (e?: React.FocusEvent | React.MouseEvent) => {
    setEditValue(null);
    onChange?.(null);
    setIsEditing(false);
  }

  const handleSave = (e?: React.FocusEvent | React.MouseEvent) => {  
    const trimmedValue = (editValue || '').trim();
    if (trimmedValue !== value) {
      onChange?.(trimmedValue);
    }
    setIsEditing(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setEditValue(newValue);
    
    if (textareaRef.current) {
      textareaRef.current.style.height = '0';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
      onActivate?.(null);
    }
    if (e.key === 'Escape') {
      setEditValue(displayValue);
      setIsEditing(false);
      onActivate?.(null);
    }
    if (e.key === 'Tab' && rowIndex !== undefined && columnIndex !== undefined) {
      e.preventDefault();
      handleSave();
      onNextCell?.(rowIndex, columnIndex);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    onActivate?.(null);
  };

  return (
    <div 
      data-component="data-table-cell"
      data-row-index={rowIndex}
      data-column-index={columnIndex}
      data-editing={isEditing}
      data-index={isIndex}
      ref={cellRef}
      onDoubleClick={(e) => {
        if (!readonly && !isEditing) {
          e.stopPropagation();
          onActivate?.({rowIndex: rowIndex!, columnIndex: columnIndex!});
        }
      }}
    >
      
      {isEditing ? (
        <textarea
          ref={textareaRef}
          value={editValue ?? ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      ) : (
        <div className="cell-content">
          {
          value === null
            ? <div className="null-value">NULL</div>
            : value === void 0
              ? <div className="null-value">???</div>
              : displayValue
          }
        </div>
      )}
      <div className="action-buttons">
        {isEditing && (
          <button 
            className="action-button null-button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleNullSave(e);
            }}
          >
            NULL
          </button>
        )}
        {!readonly && (value !== undefined) && (
          <button 
            className="action-button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isEditing) {
                handleSave(e);
              } else {
                onActivate?.({rowIndex: rowIndex!, columnIndex: columnIndex!});
              }
            }}
          >
            {isEditing ? <Check size={14} /> : <Edit2 size={14} />}
          </button>
        )}
      </div>
    </div>
  );
}
