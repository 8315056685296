import { Grid, Key, X } from 'react-feather';
import Textbox from '../../components/textbox/Textbox';
import Select from '../../components/select/Select';
import './ColumnEditor.scss';
import { useState, useEffect, useRef } from 'react';

interface ColumnData {
  name: string;
  type: string;
  originalName: string | null;
}

interface ColumnEditorProps {
  column: ColumnData;
  isDragging?: boolean;
  onDragStart?: (e: React.MouseEvent) => void;
  readonly?: boolean;
  typeOptions: { label: string; value: string; }[];
  onChange: (column: ColumnData) => void;
  onDelete?: () => void;
  autoFocus?: boolean;
}

export function ColumnEditor({ 
  column, 
  isDragging, 
  onDragStart,
  readonly,
  typeOptions,
  onChange,
  onDelete,
  autoFocus
}: ColumnEditorProps) {

  const isReadOnly = readonly || column.name === 'id';

  return (
    <div 
      data-component="ColumnEditor" 
      className={`column-row ${isDragging ? 'dragging' : ''}`}
    >
      {column.name === 'id' ? (
        <div 
          className="drag-handle empty"
        >
          <Key size={14} />
        </div>
      ) : (
        <div 
          className="drag-handle"
          onMouseDown={onDragStart}
        >
          <Grid size={14} />
        </div>
      )}
      <div className="column-name-container">
        {column.originalName && (
          <span className="original-name">{column.originalName}</span>
        )}
        <Textbox
          value={column.name}
          placeholder="Column name"
          size="small"
          readonly={isReadOnly}
          autoFocus={autoFocus}
          tabIndex={isReadOnly ? -1 : 0}
          onChange={(value) => onChange({ ...column, name: value })}
        />
      </div>
      <Select
        value={column.type}
        options={typeOptions}
        size="small"
        readonly={isReadOnly}
        tabIndex={isReadOnly ? -1 : 0}
        onChange={(value) => onChange({ ...column, type: value })}
      />
      <button 
        className="delete-button" 
        onClick={isReadOnly || !onDelete ? void 0 : onDelete}
        type="button"
        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }}
        tabIndex={isReadOnly ? -1 : 0}
      >
        {!isReadOnly && <X size={14} />}
      </button>
    </div>
  );
}
