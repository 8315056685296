/**
 * SQLite JSONB parser in TypeScript for front-end JavaScript.
 *
 * Based on the official SQLite JSONB spec at:
 *   https://sqlite.org/jsonb.html :contentReference[oaicite:1]{index=1}
 *
 * This parser decodes a base64 string representing a JSONB-encoded BLOB
 * into the corresponding JavaScript value.
 */

enum JSONBType {
  Null = 0,
  True = 1,
  False = 2,
  Int = 3,
  Int5 = 4,
  Float = 5,
  Float5 = 6,
  Text = 7,
  TextJ = 8,
  Text5 = 9,
  TextRaw = 10,
  Array = 11,
  Object = 12,
  Reserved13 = 13,
  Reserved14 = 14,
  Reserved15 = 15,
}

/**
 * Reads an unsigned big-endian integer of the given length from the data at the given offset.
 */
function readUIntBE(data: Uint8Array, offset: number, length: number): number {
  let num = 0;
  for (let i = 0; i < length; i++) {
    num = (num << 8) | data[offset + i];
  }
  return num;
}

/**
 * Recursively parses a JSONB element from the data starting at the given offset.
 * Returns the parsed value and the new offset.
 */
function parseElement(
  data: Uint8Array,
  offset: number,
  decoder: TextDecoder
): { value: any; newOffset: number } {
  if (offset >= data.length) {
    throw new Error("Unexpected end of data while reading header.");
  }

  // Read the first header byte.
  const header = data[offset];
  const sizeIndicator = header >> 4; // Upper 4 bits: determine header/payload size.
  const elementType = header & 0x0f; // Lower 4 bits: element type.
  let headerLength = 1;
  let payloadLength: number;

  if (sizeIndicator <= 11) {
    // One-byte header: payload length is simply the sizeIndicator.
    payloadLength = sizeIndicator;
  } else {
    // Extended header: sizeIndicator indicates header length and that the
    // payload length is stored in the following bytes.
    let extraBytes = 0;
    switch (sizeIndicator) {
      case 12:
        extraBytes = 1;
        break;
      case 13:
        extraBytes = 2;
        break;
      case 14:
        extraBytes = 4;
        break;
      case 15:
        extraBytes = 8;
        break;
      default:
        throw new Error("Invalid size indicator in JSONB header.");
    }
    headerLength = 1 + extraBytes;
    if (offset + headerLength > data.length) {
      throw new Error("Not enough data for extended header.");
    }
    payloadLength = readUIntBE(data, offset + 1, extraBytes);
  }
  offset += headerLength; // Move past header.

  if (offset + payloadLength > data.length) {
    throw new Error("Not enough data for payload.");
  }
  const payloadBytes = data.subarray(offset, offset + payloadLength);
  let value: any;

  // Process according to element type.
  switch (elementType) {
    case JSONBType.Null:
      if (payloadLength !== 0) {
        throw new Error("Invalid payload length for NULL element.");
      }
      value = null;
      break;
    case JSONBType.True:
      if (payloadLength !== 0) {
        throw new Error("Invalid payload length for TRUE element.");
      }
      value = true;
      break;
    case JSONBType.False:
      if (payloadLength !== 0) {
        throw new Error("Invalid payload length for FALSE element.");
      }
      value = false;
      break;
    case JSONBType.Int:
    case JSONBType.Int5: {
      // Payload is the ASCII text representation of the integer.
      const text = decoder.decode(payloadBytes);
      value = Number(text);
      break;
    }
    case JSONBType.Float:
    case JSONBType.Float5: {
      // Payload is the ASCII text representation of the floating-point number.
      const text = decoder.decode(payloadBytes);
      value = Number(text);
      break;
    }
    case JSONBType.Text: {
      // Plain text string without escapes.
      value = decoder.decode(payloadBytes);
      break;
    }
    case JSONBType.TextJ:
    case JSONBType.Text5: {
      // Text with escapes. Wrap in quotes and use JSON.parse to unescape.
      const rawText = decoder.decode(payloadBytes);
      try {
        value = JSON.parse('"' + rawText + '"');
      } catch (e: any) {
        throw new Error("Failed to parse escaped text: " + e.message);
      }
      break;
    }
    case JSONBType.TextRaw: {
      // Text that may require escaping if rendered as standard JSON.
      value = decoder.decode(payloadBytes);
      break;
    }
    case JSONBType.Array: {
      // The payload is a sequence of JSONB elements.
      const arr: any[] = [];
      const end = offset + payloadLength;
      let localOffset = offset;
      while (localOffset < end) {
        const result = parseElement(data, localOffset, decoder);
        arr.push(result.value);
        localOffset = result.newOffset;
      }
      if (localOffset !== end) {
        throw new Error("Array payload did not consume the expected number of bytes.");
      }
      value = arr;
      break;
    }
    case JSONBType.Object: {
      // The payload is a sequence of key-value pairs.
      const obj: Record<string, any> = {};
      const end = offset + payloadLength;
      let localOffset = offset;
      while (localOffset < end) {
        // Parse key (must be a string).
        const keyResult = parseElement(data, localOffset, decoder);
        if (typeof keyResult.value !== "string") {
          throw new Error("Object key is not a string.");
        }
        localOffset = keyResult.newOffset;
        // Parse value.
        const valResult = parseElement(data, localOffset, decoder);
        localOffset = valResult.newOffset;
        obj[keyResult.value] = valResult.value;
      }
      if (localOffset !== end) {
        throw new Error("Object payload did not consume the expected number of bytes.");
      }
      value = obj;
      break;
    }
    default:
      throw new Error("Unsupported or reserved JSONB element type: " + elementType);
  }
  offset += payloadLength; // Move past payload.
  return { value, newOffset: offset };
}

/**
 * Converts a base64-encoded string to a Uint8Array.
 */
function base64ToUint8Array(base64: string): Uint8Array {
  // In browsers, atob converts base64 to a binary string.
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

/**
 * Parses a SQLite JSONB value from a base64 string.
 *
 * @param base64 - The base64-encoded JSONB BLOB.
 * @returns The corresponding JavaScript value.
 */
export function parseSQLiteJSONB(base64: string): any {
  const data = base64ToUint8Array(base64);
  const decoder = new TextDecoder("utf-8");
  const result = parseElement(data, 0, decoder);
  if (result.newOffset !== data.length) {
    throw new Error("Extra data found after parsing the JSONB blob.");
  }
  return result.value;
}

/* Example Usage:

const base64Data = "YOUR_BASE64_STRING_HERE";
try {
  const parsedValue = parseSQLiteJSONB(base64Data);
  console.log("Parsed JSONB value:", parsedValue);
} catch (err) {
  console.error("Error parsing JSONB:", err);
}

*/

export const JSONB = {
  parseBase64: (base64: string) => {
    const data = base64ToUint8Array(base64);
    const decoder = new TextDecoder("utf-8");
    const result = parseElement(data, 0, decoder);
    return result.value;
  }
}
