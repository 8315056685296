import { useState, useEffect } from "react";
import { useLoaderData, useSearchParams, useNavigate, Link } from "react-router-dom";
import {
  Play,
  Table as TableIcon,
  MessageSquare,
  Trash2,
  X,
  Settings,
  Plus,
  Database,
  Link2,
  ChevronRight,
  ChevronDown
} from "react-feather";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";

import { AgentSchema, SqliteDatabaseSchema } from "../../utils/types";
import API from "../../utils/api";

import "./DatabasesPage.scss";
import Textbox from "../../components/textbox/Textbox";
import Button from "../../components/button/Button";
import Toggle from "../../components/toggle/Toggle";
import DataTable, { DataTableMeta } from "../../components/datatable/DataTable";
import { AlertModal } from "../../components/modals/AlertModal";
import { TableEditor } from './TableEditor';
import { InsertRowEditor } from './InsertRowEditor';

interface TableSchema {
  sql: string;
  tables: {
    name: string;
    columns: {
      name: string;
      type: string;
      type_affinity: string;
    }[];
  }[];
}

interface TableResponse {
  sqlite_database: SqliteDatabaseSchema;
  meta: {
    total: number;
    count: number;
    offset: number;
  };
  data: any[];
}

export default function DatabasesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Replace selectedTable state with query param
  const selectedTable = searchParams.get('table') || null;

  // Get the initial sqliteDatabase from the loader
  const agent = useLoaderData() as AgentSchema;
  const agentName = agent.agentConfigs[0]?.name || "Untitled Agent";
  const [sqliteDatabase, setSqliteDatabase] = useState<SqliteDatabaseSchema | null>(
    agent.agentDbs[0]?.sqliteDatabase
  );

  // The table schema can be null or an object
  const [tableSchema, setTableSchema] = useState<TableSchema | null>(sqliteDatabase?.table_schema || null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableMeta, setTableMeta] = useState<TableResponse["meta"] | null>(null);
  const [tableQuery, setTableQuery] = useState<string | null>(null);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [naturalLanguageInput, setNaturalLanguageInput] = useState('');
  const [isNaturalLanguageProcessing, setIsNaturalLanguageProcessing] = useState(false);
  const [sqlInput, setSqlInput] = useState('');
  const [showSqlEditor, setShowSqlEditor] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const [tableToDelete, setTableToDelete] = useState<string | null>(null);
  const [tableToTruncate, setTableToTruncate] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentSearch, setCurrentSearch] = useState<Record<string, string>>({});
  const rowsPerPage = 50;

  // Add new state for table editor
  const [showTableEditor, setShowTableEditor] = useState<'create' | 'edit' | false>(false);

  // Add new state for search
  const [searchField, setSearchField] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  // Add state for insert modal
  const [showInsertRow, setShowInsertRow] = useState(false);

  // Build TanStack Table columns from the table definition in the schema.
  let columns: ColumnDef<any>[] = [];
  if (selectedTable && tableSchema) {
    const tableDef = tableSchema.tables.find((t) => t.name === selectedTable);
    if (tableDef) {
      columns = tableDef.columns.map((col) => ({
        id: col.name,
        accessorKey: col.name,
        header: () => (
          <div className="column-header">
            <span className="column-name">{col.name}</span>
          </div>
        ),
      }));
    }
  }

  // Create the TanStack table instance.
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Load first table when schema is available
  useEffect(() => {
    if (tableSchema && (tableSchema?.tables?.length || 0) > 0 && !selectedTable) {
      const firstTable = tableSchema.tables[0].name;
      setSearchParams({ table: firstTable });
    }
  }, [tableSchema]);

  // When we change the selected table, load the table data
  useEffect(() => {
    if (selectedTable) {
      loadTableData({tableName: selectedTable, page: 0, search: {}, initialLoad: true});
    }
  }, [selectedTable]);

  // When the schema is missing, show an error
  if (!sqliteDatabase) {
    return (
      <div data-component="DatabasesPage">
        Invalid Database
      </div>
    );
  }

  const loadTableData = async ({
    tableName,
    page = 0,
    search = {},
    initialLoad = false,
    selectQuery
  } : {
    tableName: string, 
    page?: number,
    search?: Record<string, string>,
    selectQuery?: string | null,
    initialLoad?: boolean
  }) => {
    initialLoad && setIsTableLoading(true);
    let result;
    try {
      // If we already have a table query, use it, otherwise set it
      if (selectQuery !== null && (selectQuery || tableQuery)) {
        result = await API.get(
          "v1/sqlite_databases/commands",
          {
            id: sqliteDatabase?.unique_id,
            tableName,
            selectQuery: selectQuery || tableQuery
          }
        );
        setTableQuery(selectQuery || tableQuery);
      } else {
        result = await API.get(
          "v1/sqlite_databases/commands",
          {
            id: sqliteDatabase?.unique_id,
            tableName,
            limit: { 
              count: rowsPerPage, 
              offset: page * rowsPerPage 
            },
            ...(Object.keys(search).length > 0 && { search })
          }
        );
        setTableQuery(null);
      }
      const tableResponse = result as TableResponse;
      setTableData(tableResponse.data);
      setTableMeta(tableResponse.meta);
      setSqliteDatabase(tableResponse.sqlite_database);
    } catch (error) {
      console.error("Error loading table:", error);
      setTableData([]);
      setTableMeta(null);
    }
    initialLoad && setIsTableLoading(false);
  };

  const handleTableClick = (tableName: string) => {
    setTableQuery(null);
    setTableData([]);
    setSearchParams({ table: tableName });
  };

  const handleSearch = async (search: Record<string, string>) => {
    setTableQuery(null);
    setCurrentSearch(search);
    setCurrentPage(0); // Reset to first page on new search
    if (selectedTable) {
      await loadTableData({tableName: selectedTable, page: 0, search});
    }
  };

  const handlePageChange = async (page: number) => {
    setTableQuery(null);
    setCurrentPage(page);
    if (selectedTable) {
      await loadTableData({tableName: selectedTable, page, search: currentSearch});
    }
  };

  const runSql = async (sql: string) => {
    try {
      setIsRunning(true);
      const result = await API.get(
        'v1/sqlite_databases/execute',
        {
          id: sqliteDatabase.unique_id,
          query: sql
        }
      );
      if (result) {
        const schema = result.sqlite_database.table_schema as TableSchema;
        setTableSchema(schema);
        if (result?.view_table) {
          // select the corresponding table in the table list if it exists
          const tableIndex = schema.tables.findIndex(t => t.name === result.view_table);
          if (
            tableIndex !== -1 &&
            result.view_table !== selectedTable
          ) {
            setCurrentPage(0);
            setCurrentSearch({});
            setTableQuery(result.select?.query || null);
            setSearchParams({ table: result.view_table });
          } else if (selectedTable) {
            // Reset to first page with no search after SQL execution
            setCurrentPage(0);
            setCurrentSearch({});
            setTableQuery(null);
            await loadTableData({tableName: selectedTable, page: 0, search: {}, selectQuery: result.select?.query || null});
          }
        } else if (selectedTable) {
          // Reset to first page with no search after SQL execution
          setCurrentPage(0);
          setCurrentSearch({});
          setTableQuery(null)
          await loadTableData({tableName: selectedTable, page: 0, search: {}});
        }
      }
      setIsRunning(false);
    } catch (e) {
      setIsRunning(false);
      const error = e as Error;
      setAlertMessage(error.message);
    }
  };

  const processNaturalLanguage = async (input: string) => {
    try {
      setIsNaturalLanguageProcessing(true);
      const result = await API.get(
        'v1/sqlite_databases/natural_language',
        {
          id: sqliteDatabase.unique_id,
          input,
          tableName: selectedTable
        }
      );
      if (result?.query) {
        const lines = input.split('\n').map(line => `-- ${line.trim()}`).join('\n');
        const sql = `${lines}\n${result.query}`;
        setSqlInput(sql);
        setNaturalLanguageInput('');
        setShowSqlEditor(true);
      }
    } catch (e) {
      const error = e as Error;
      setAlertMessage(error.message);
    } finally {
      setIsNaturalLanguageProcessing(false);
    }
  };

  const handleDeleteTableClick = (tableName: string) => {
    setTableToDelete(tableName);
  };

  const handleDeleteTable = async () => {
    try {
      const result = await API.post('v1/sqlite_databases/commands', {
        id: sqliteDatabase.unique_id,
        command: 'dropTable',
        params: {
          tableName: tableToDelete
        }
      });
      if (result) {
        setTableSchema(result.sqlite_database.table_schema);
        // If we deleted the currently selected table, select another one
        if (tableToDelete === selectedTable) {
          const tables = result.sqlite_database.table_schema.tables;
          if (tables.length > 0) {
            // Find index of deleted table in original list
            const deletedIndex = tableSchema?.tables.findIndex(t => t.name === tableToDelete) ?? -1;
            // Select the next table, or the previous if we deleted the last one
            const nextTable = tables[deletedIndex] || tables[deletedIndex - 1];
            if (nextTable) {
              setSearchParams({ table: nextTable.name });
              // loadTableData({tableName: nextTable.name, page: 0, search: {}});
              // setTableSchema(result.sqlite_database.table_schema);
            }
          } else {
            setSearchParams({}); // Only clear if no tables left
          }
        }
        setTableToDelete(null);
      }
    } catch (e) {
      setAlertMessage((e as Error).message);
    }
  };

  const handleBulkAction = async (action: string, rows: any[]) => {
    if (action === 'delete' && sqliteDatabase) {
      try {
        // Find the primary key column (assuming first column)
        const idColumn = tableSchema?.tables.find(t => t.name === selectedTable)?.columns[0].name;
        if (!idColumn) throw new Error("No identifier column found");

        await API.post("v1/sqlite_databases/commands", {
          id: sqliteDatabase.unique_id,
          command: "deleteFrom",
          params: {
            tableName: selectedTable,
            columnName: idColumn,
            values: rows.map(row => row[idColumn])
          }
        });

        // Refresh current page
        if (tableMeta && selectedTable) {
          await loadTableData({tableName: selectedTable, page: currentPage, search: currentSearch});
        }
      } catch (error) {
        console.error("Error deleting rows:", error);
        setAlertMessage((error as Error).message);
      }
    }
  };

  const handleCellEdit = async ({ rowIndex, columnId, value }: { 
    rowIndex: number; 
    columnId: string; 
    value: string | null; 
  }) => {
    // Create copy of current data for optimistic update
    const updatedData = [...tableData];
    const updatedRow = { ...updatedData[rowIndex], [columnId]: value };
    updatedData[rowIndex] = updatedRow;
    
    // Update immediately for optimistic UI
    setTableData(updatedData);

    try {
      // Make API call to update the row
      const result = await API.post("v1/sqlite_databases/commands", {
        id: sqliteDatabase?.unique_id,
        command: "updateWhere",
        params: {
          tableName: selectedTable,
          rows: [updatedRow]
        }
      });

      // Update the returned row in current table data if it exists
      if (result?.result?.[0]) {
        const returnedRow = result.result[0];
        // Find the primary key column (assuming first column)
        const idColumn = tableSchema?.tables.find(t => t.name === selectedTable)?.columns[0].name;
        if (idColumn) {
          // Get latest table data and find matching row
          const latestData = [...tableData];
          const matchingIndex = latestData.findIndex(row => row[idColumn] === returnedRow[idColumn]);
          if (matchingIndex >= 0) {
            latestData[matchingIndex] = returnedRow;
            setTableData(latestData);
          }
        }
      }
    } catch (error) {
      // On error, revert the optimistic update
      console.error("Error updating row:", error);
      setTableData(tableData); // Revert to original data
      setAlertMessage((error as Error).message);
    }
  };

  // Add truncate handler
  const handleTruncateTable = async () => {
    try {
      await API.post('v1/sqlite_databases/commands', {
        id: sqliteDatabase.unique_id,
        command: 'truncateTable',
        params: { tableName: tableToTruncate }
      });
      if (tableToTruncate && tableToTruncate === selectedTable) {
        setTableToTruncate(null);
        await loadTableData({tableName: tableToTruncate, page: 0, search: {}});
      }
    } catch (e) {
      setAlertMessage((e as Error).message);
    }
  };

  // Add handler for inserting row
  const handleInsertRow = async (row: Record<string, any>) => {
    try {
      const result = await API.post("v1/sqlite_databases/commands", {
        id: sqliteDatabase?.unique_id,
        command: "insertInto",
        params: {
          tableName: selectedTable,
          rows: [row],
          upsert: false
        }
      });

      // Refresh current page
      if (selectedTable) {
        await loadTableData({tableName: selectedTable, page: currentPage, search: currentSearch});
      }
      
      setShowInsertRow(false);
    } catch (error) {
      console.error("Error inserting row:", error);
      setAlertMessage((error as Error).message);
    }
  };

  return (
    <>
      <div data-component="DatabasesPage">
        <div className="databases-content">
          <div className="databases-title">
            <Database />
            <span className="databases-title-name">
              {sqliteDatabase.name}
            </span>
            <span className="databases-title-agent">
              <Link2 /> <Link to={`/agents/${agent.unique_id}`}>{agentName}</Link>
            </span>
          </div>
          <div className="databases-tabs">
            {tableSchema?.tables.map((tableObj) => (
              <button
                key={tableObj.name}
                className={`tab ${selectedTable === tableObj.name ? 'active' : ''}`}
                onClick={() => handleTableClick(tableObj.name)}
              >
                <TableIcon size={16} />
                <span>{tableObj.name}</span>
              </button>
            ))}
            <button 
              className="tab new-table"
              onClick={() => setShowTableEditor('create')}
            >
              <Plus size={16} />
              <span>Create table</span>
            </button>
          </div>
          <div className="databases-table">
            {selectedTable ? (
              <>
                <div className="table-actions">
                  <div className="natural-language-input">
                    <Textbox
                      type="chat"
                      emptyChatOnSubmit={false}
                      lines={1}
                      maxLines={4}
                      size="small"
                      placeholder="What do you want to do?"
                      value={naturalLanguageInput}
                      onChange={value => setNaturalLanguageInput(value)}
                      onSubmit={value => processNaturalLanguage(value)}
                    />
                    <Button
                      label={"Generate"}
                      icon={MessageSquare}
                      size="small"
                      loading={isNaturalLanguageProcessing}
                      disabled={isNaturalLanguageProcessing}
                      onClick={() => processNaturalLanguage(naturalLanguageInput)}
                    />
                    <Button
                      label={"SQL editor"}
                      icon={showSqlEditor ? ChevronDown : ChevronRight}
                      size="small"
                      onClick={() => setShowSqlEditor(!showSqlEditor)}
                    />
                  </div>
                  <Button
                    icon={Settings}
                    size="small"
                    label="Edit table"
                    onClick={() => setShowTableEditor('edit')}
                  />
                  <Button
                    icon={X}
                    size="small"
                    label="Truncate table"
                    onClick={() => setTableToTruncate(selectedTable)}
                  />
                  <Button
                    icon={Trash2}
                    size="small"
                    label="Drop table"
                    onClick={() => handleDeleteTableClick(selectedTable)}
                  />
                </div>
                {showSqlEditor && (
                  <div className="databases-sql-editor">
                    <Textbox
                      type="multiline"
                      lines={4}
                      maxLines={4}
                      value={sqlInput}
                      monospace={true}
                      onChange={value => setSqlInput(value)}
                      onSubmit={value => runSql(value)}
                    />
                    <div className="databases-sql-editor-actions">
                      <Button
                        label="Run SQL"
                        hotkeys="cmd+enter"
                        icon={Play}
                        size="small"
                        color="green"
                        loading={isRunning}
                        disabled={isRunning}
                        onClick={() => runSql(sqlInput)}
                      />
                    </div>
                  </div>
                )}
                <DataTable
                  data={tableData}
                  meta={tableMeta || void 0}
                  query={tableQuery}
                  columns={columns.length > 0 ? columns : [{ 
                    id: 'empty',
                    header: 'No columns' 
                  }]}
                  loading={isTableLoading}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onQueryClear={async () => {
                    setCurrentPage(0);
                    setCurrentSearch({});
                    await loadTableData({tableName: selectedTable, page: 0, search: {}, selectQuery: null});
                  }}
                  onSearch={handleSearch}
                  onBulkAction={handleBulkAction}
                  onCellEdit={handleCellEdit}
                  onSearchFieldChange={setSearchField}
                  onSearchValueChange={setSearchValue}
                  onInsertRow={() => setShowInsertRow(true)}
                />
              </>
            ) : (tableSchema?.tables.length || 0) === 0 ? (
              <div className="no-table">
                <p>No tables exist in this database yet.</p>
                <Button
                  icon={Plus}
                  label="Create table" 
                  onClick={() => setShowTableEditor('create')}
                />
              </div>
            ) : (
              <div className="no-table">Select a table to view data</div>
            )}
          </div>
        </div>
      </div>
      {tableToDelete && (
        <AlertModal
          message={`Are you sure you want to drop table "${tableToDelete}"? This action cannot be undone.`}
          color="red"
          onConfirm={handleDeleteTable}
          onCancel={() => setTableToDelete(null)}
          onClose={() => setTableToDelete(null)}
        />
      )}
      {tableToTruncate && (
        <AlertModal
          message={`Are you sure you want to truncate table "${tableToTruncate}"? This will delete all rows but keep the table structure. This action cannot be undone.`}
          color="red"
          onConfirm={handleTruncateTable}
          onCancel={() => setTableToTruncate(null)}
          onClose={() => setTableToTruncate(null)}
        />
      )}
      {alertMessage && (
        <AlertModal
          message={alertMessage}
          onConfirm={() => setAlertMessage('')}
        />
      )}
      {showTableEditor && (
        <TableEditor
          mode={showTableEditor === 'create' ? 'create' : 'edit'}
          databaseId={sqliteDatabase.unique_id}
          tableName={showTableEditor === 'create' ? '' : selectedTable || ''}
          columns={showTableEditor === 'create' ? [] : (
            tableSchema?.tables.find(t => t.name === selectedTable)?.columns || []
          )}
          onSave={async (data) => {
            // First update schema
            setTableSchema(data.schema as TableSchema);
            
            // Then update URL and selected table
            setSearchParams({ table: data.tableName });
            
            // Close modal
            setShowTableEditor(false);
            
            // Finally reload the table data with new schema
            await loadTableData({tableName: data.tableName, page: 0, search: {}});
            
            // Also update the database object to keep it in sync
            setSqliteDatabase(prev => prev ? {
              ...prev,
              table_schema: data.schema
            } : null);
          }}
          onClose={() => setShowTableEditor(false)}
        />
      )}
      {showInsertRow && selectedTable && tableSchema && (
        <InsertRowEditor
          tableName={selectedTable}
          columns={tableSchema.tables.find(t => t.name === selectedTable)?.columns || []}
          onSave={handleInsertRow}
          onClose={() => setShowInsertRow(false)}
        />
      )}
    </>
  );
}
