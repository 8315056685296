import { useState, useRef, useEffect } from 'react';
import Button from '../../components/button/Button';
import Textbox from '../../components/textbox/Textbox';
import Select from '../../components/select/Select';
import { Plus, X, RotateCcw, Check } from 'react-feather';
import './TableEditor.scss';
import { ColumnEditor } from './ColumnEditor';
import API from '../../utils/api';

interface ColumnData {
  name: string;
  type: string;
  originalName: string | null;  // null means it's a new column
  system?: boolean;  // Add this
}

interface TableEditorProps {
  mode: 'create' | 'edit';
  tableName?: string;  // Optional for create mode
  databaseId: string;
  columns?: Array<{    // Optional for create mode
    name: string;
    type: string;
  }>;
  onSave: (data: { tableName: string; schema: any }) => void;
  onClose: () => void;
}

export function TableEditor({ 
  mode = 'edit',
  tableName: initialTableName = '', 
  columns: initialColumns = [], 
  databaseId, 
  onSave, 
  onClose 
}: TableEditorProps) {
  const [tableName, setTableName] = useState(mode === 'create' ? 'new_table' : initialTableName);
  const [columns, setColumns] = useState<ColumnData[]>(() => {
    if (mode === 'create') {
      return [
        // Show ID column in UI but mark it special
        { name: 'id', type: 'INTEGER', originalName: null, system: true },
        { name: 'name', type: 'TEXT', originalName: null },
        { name: 'created_at', type: 'DATETIME', originalName: null }
      ];
    }
    return initialColumns.map(col => ({
      ...col,
      type: col.type.toUpperCase(),
      originalName: col.name
    }));
  });
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [focusNewColumn, setFocusNewColumn] = useState(false);
  const dragCloneRef = useRef<HTMLElement | null>(null);
  const mouseOffsetX = useRef(0);
  const mouseOffsetY = useRef(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const tableNameRef = useRef<HTMLInputElement>(null);

  const typeOptions = [
    { label: 'text', value: 'TEXT' },
    { label: 'integer', value: 'INTEGER' },
    { label: 'datetime', value: 'DATETIME' },
    { label: 'json', value: 'JSON' },
    { label: 'jsonb', value: 'JSONB' },
    { label: 'numeric', value: 'NUMERIC' },
    { label: 'real', value: 'REAL' },
    { label: 'blob', value: 'BLOB' }
  ];


  // Track changes and validate
  useEffect(() => {
    // Check if anything changed from initial state
    const schemaChanged = 
      tableName !== initialTableName ||
      columns.length !== initialColumns.length ||
      columns.some((col, i) => 
        col.name !== (col.originalName || '') ||
        col.type !== initialColumns[i]?.type.toUpperCase() ||
        (col.originalName && 
          initialColumns.findIndex(ic => ic.name === col.originalName) !== i)
      );
    
    setHasChanges(schemaChanged);
    
    // Validate table name and column names
    const isValidSchema = 
      tableName.trim() !== '' &&
      columns.every(col => col.name.trim() !== '');
    
    setIsValid(isValidSchema);
  }, [tableName, columns, initialTableName, initialColumns]);

  // Add effect to focus and select text when modal opens in create mode
  useEffect(() => {
    if (mode === 'create' && tableNameRef.current) {
      tableNameRef.current.focus();
      tableNameRef.current.select();
    }
  }, [mode]);

  const handleSave = async () => {
    setError(null);
    setIsLoading(true);

    try {
      if (mode === 'create') {
        const result = await API.post('v1/sqlite_databases/commands', {
          id: databaseId,
          command: 'createTable',
          params: {
            tableName,
            columns: columns
              .filter(col => !col.system)
              .map(col => ({
                name: col.name,
                type: col.type
              }))
          }
        });
        if (result) {
          onSave({
            tableName,
            schema: result.sqlite_database.table_schema
          });
        }
      } else {
        let reorderColumns = false;
        // Check if any columns were reordered OR had type changes
        for (let i = 1; i < Math.min(initialColumns.length, columns.length); i++) {
          if (columns[i].name !== initialColumns[i].name || 
              columns[i].type !== initialColumns[i].type.toUpperCase()) {
            reorderColumns = true;
            break;
          }
        }

        const changes = {
          id: databaseId,
          tableName: initialTableName,
          newTableName: tableName !== initialTableName ? tableName : null,
          droppedColumnNames: initialColumns
            .map(col => col.name)
            .filter(name => !columns.find(c => c.originalName === name)),
          renamedColumns: columns
            .filter(col => col.originalName && col.originalName !== col.name)
            .map(col => ({ columnName: col.originalName!, newColumnName: col.name })),
          alteredColumns: columns
            .filter(col => col.originalName && 
              initialColumns.find(ic => ic.name === col.originalName)?.type !== col.type)
            .map(col => ({ name: col.name, type: col.type })),
          addedColumns: columns
            .filter(col => !col.originalName)
            .map(col => ({ name: col.name, type: col.type })),
          columnNames: columns.map(col => col.name),
          reorderColumns: reorderColumns
        };

        const result = await API.put('v1/sqlite_databases/commands', changes);
        if (result) {
          onSave({
            tableName: changes.newTableName || changes.tableName,
            schema: result.sqlite_database.table_schema
          });
        } else {
          throw new Error('No result from API');
        }
      }
    } catch (e) {
      setError((e as Error).message);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    if (mode === 'create') {
      // For create mode, reset to initial state but preserve system columns
      const systemColumns = columns.filter(col => col.system);
      const defaultColumns = [
        // Keep system columns like ID
        ...systemColumns,
        // Reset other columns to defaults
        { name: 'name', type: 'TEXT', originalName: null },
        { name: 'created_at', type: 'DATETIME', originalName: null }
      ];
      setColumns(defaultColumns);
      setTableName('new_table');
    } else {
      // For edit mode, reset to initial state
      setTableName(initialTableName);
      setColumns(initialColumns.map(col => ({
        ...col,
        type: col.type.toUpperCase(),
        originalName: col.name
      })));
    }
  };

  const handleDragStart = (e: React.MouseEvent, index: number) => {
    if (index === 0) return;

    const editor = e.currentTarget.closest('[data-component="ColumnEditor"]') as HTMLElement;
    if (!editor) return;

    // Prevent text selection while dragging
    document.body.style.userSelect = 'none';
    document.body.style.webkitUserSelect = 'none';
    document.body.style.cursor = 'grabbing';

    // Create and position clone
    const clone = editor.cloneNode(true) as HTMLElement;
    const rect = editor.getBoundingClientRect();
    
    // Preserve the select value in the clone
    const originalSelect = editor.querySelector('select');
    const cloneSelect = clone.querySelector('select');
    if (originalSelect && cloneSelect) {
      cloneSelect.value = originalSelect.value;
    }
    
    mouseOffsetX.current = e.clientX - rect.left;
    mouseOffsetY.current = e.clientY - rect.top;

    Object.assign(clone.style, {
      position: 'fixed',
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      left: `${rect.left}px`,
      top: `${rect.top}px`,
      background: 'var(--background-tertiary)',
      boxShadow: 'var(--shadow-floating)',
      pointerEvents: 'none',
      zIndex: '9999',
      borderRadius: 'var(--radius-lg)',
    });

    document.body.appendChild(clone);
    dragCloneRef.current = clone;
    setDraggedIndex(index);
  };

  useEffect(() => {
    if (draggedIndex === null) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (!dragCloneRef.current) return;

      // Keep original x position, only update y
      const originalRect = dragCloneRef.current.getBoundingClientRect();
      dragCloneRef.current.style.left = `${originalRect.left}px`;
      dragCloneRef.current.style.top = `${e.clientY - mouseOffsetY.current}px`;

      // Find new position and reorder columns
      const editors = document.querySelectorAll('[data-component="ColumnEditor"]');
      let newIndex = columns.length;  // Default to end of list
      
      for (let i = 1; i < editors.length; i++) {
        const rect = editors[i].getBoundingClientRect();
        if (e.clientY < rect.top + (rect.height / 2)) {
          newIndex = i;
          break;
        }
      }
      
      // Only update if position changed
      if (newIndex !== draggedIndex) {
        const newColumns = [...columns];
        const [moved] = newColumns.splice(draggedIndex, 1);
        newIndex = newIndex > draggedIndex ? newIndex - 1 : newIndex;
        newColumns.splice(newIndex, 0, moved);
        setColumns(newColumns);
        setDraggedIndex(newIndex);
      }
    };

    const handleMouseUp = () => {
      if (dragCloneRef.current) {
        document.body.removeChild(dragCloneRef.current);
        dragCloneRef.current = null;
      }
      setDraggedIndex(null);
      document.body.style.userSelect = '';
      document.body.style.webkitUserSelect = '';
      document.body.style.cursor = '';
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [draggedIndex, columns]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (dragCloneRef.current) {
        document.body.removeChild(dragCloneRef.current);
        dragCloneRef.current = null;
      }
      document.body.style.userSelect = '';
      document.body.style.webkitUserSelect = '';
      document.body.style.cursor = '';
      setDraggedIndex(null);
    };
  }, []);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-toolbar">
          <h3>
            {mode === 'create' ? 'Create new table' : `Edit table: ${initialTableName}`}
          </h3>
          <button className="close-button" onClick={onClose}>
            <X size={20} />
          </button>
        </div>
        
        <div className="modal-body">
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          <div className="form-group" style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}>
            <Textbox
              ref={tableNameRef}
              value={tableName}
              placeholder="Table name"
              onChange={setTableName}
            />
          </div>

          <div className="form-group">
            <div 
              className="columns-list"
              style={{ pointerEvents: draggedIndex !== null ? 'none' : 'auto' }}
            >
              {columns.map((column, index) => (
                <ColumnEditor
                  key={column.originalName || `new-${index}`}
                  column={column}
                  isDragging={index === draggedIndex}
                  onDragStart={(e) => handleDragStart(e, index)}
                  readonly={column.system || index === draggedIndex}
                  typeOptions={typeOptions}
                  autoFocus={focusNewColumn && index === columns.length - 1}
                  onChange={(updated) => {
                    if (column.system) return;
                    const newColumns = [...columns];
                    newColumns[index] = updated;
                    setColumns(newColumns);
                  }}
                  onDelete={() => {
                    if (column.system) return;
                    const newColumns = [...columns];
                    newColumns.splice(index, 1);
                    setColumns(newColumns);
                  }}
                />
              ))}
            </div>
            <div className="add-column-container">
              <Button
                label="Add column"
                icon={Plus}
                size="small"
                onClick={() => {
                  setColumns([
                    ...columns, 
                    { name: '', type: 'TEXT', originalName: null }
                  ]);
                  setFocusNewColumn(true);
                }}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button
            label="Cancel"
            icon={X}
            size="small"
            onClick={onClose}
            disabled={isLoading}
          />
          <Button
            label="Reset"
            icon={RotateCcw}
            size="small"
            disabled={!hasChanges || isLoading}
            onClick={handleReset}
          />
          <Button
            label="Save changes"
            icon={Check}
            color="blue"
            size="small"
            disabled={!hasChanges || !isValid || isLoading}
            loading={isLoading}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
