import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Check, Trash2, Search, Loader, Plus, X, Code } from "react-feather";
import { AlertModal } from "../modals/AlertModal";
import "./DataTable.scss";
import { useState, useEffect } from "react";
import { DataTableCell } from "./DataTableCell";

export interface DataTableMeta {
  total: number;
  count: number;
  offset: number;
}

export interface DataTableProps<T> {
  data: T[];
  meta?: DataTableMeta;
  query?: string | null;
  columns: ColumnDef<T>[];
  loading?: boolean;
  rowsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onQueryClear?: () => void;
  onSearch?: (search: Record<string, string>) => void;
  onBulkAction?: (action: string, rows: T[]) => void;
  onCellEdit?: (params: { rowIndex: number, columnId: string, value: string | null }) => void;
  onSearchFieldChange?: (field: string) => void;
  onSearchValueChange?: (value: string) => void;
  onInsertRow?: () => void;
}

function Pagination({ 
  currentPage, 
  totalPages, 
  meta, 
  onPageChange,
  searchInfo
}: { 
  currentPage: number;
  totalPages: number;
  meta: DataTableMeta;
  onPageChange: (page: number) => void;
  searchInfo?: { field: string; value: string; };
}) {
  return (
    <div className="table-pagination">
      <div className="nav-left">
        <button 
          className="page-nav" 
          disabled={currentPage === 0}
          onClick={() => onPageChange(0)}
        >
          <ChevronsLeft size={16} />
        </button>
        <button 
          className="page-nav"
          disabled={currentPage === 0}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ChevronLeft size={16} />
        </button>
      </div>
      <div className="pagination-info">
        <span>
          {meta.offset + 1} - {Math.min(meta.offset + meta.count, meta.total)} of {meta.total}
        </span>
      </div>
      <div className="nav-right">
        <button 
          className="page-nav"
          disabled={currentPage >= totalPages - 1}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <ChevronRight size={16} />
        </button>
        <button 
          className="page-nav"
          disabled={currentPage >= totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          <ChevronsRight size={16} />
        </button>
      </div>
    </div>
  );
}

function TableSearch({ 
  columns, 
  onSearch,
  loading,
  onSearchInfoChange
}: { 
  columns: ColumnDef<any>[];
  onSearch?: (search: Record<string, string>) => void;
  loading?: boolean;
  onSearchInfoChange?: (info: { field: string; value: string; } | undefined) => void;
}) {
  const [searchField, setSearchField] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [showLoading, setShowLoading] = useState(true);

  // Debounce the loading indicator
  useEffect(() => {
    if (!loading) {
      setShowLoading(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowLoading(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [loading]);

  const handleSearch = (value: string, field: string = searchField) => {
    setSearchValue(value);
    if (!onSearch) return;

    const trimmedValue = value.trim();
    
    // Update search info
    if (onSearchInfoChange) {
      if (!trimmedValue) {
        onSearchInfoChange(undefined);
      } else {
        onSearchInfoChange({ field, value: trimmedValue });
      }
    }

    // Always trigger search, even for empty value
    if (!trimmedValue) {
      onSearch({});
      return;
    }

    if (field === "") {
      const searchObj = columns.reduce((acc, col) => {
        if ('accessorKey' in col) {
          acc[col.accessorKey as string] = trimmedValue;
        }
        return acc;
      }, {} as Record<string, string>);
      onSearch(searchObj);
    } else {
      onSearch({ [field]: trimmedValue });
    }
  };

  return (
    <div className="table-search">
      <div className="search-input">
        <input
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Search size={14} />
        {showLoading && (
          <div className="search-loading">
            <Loader size={14} className="spin" />
          </div>
        )}
      </div>
      <div className="select-container">
        <select 
          value={searchField} 
          onChange={(e) => {
            const newField = e.target.value;
            setSearchField(newField);
            handleSearch(searchValue, newField);
          }}
        >
          <option value="">All fields</option>
          {columns.map(col => (
            'accessorKey' in col && (
              <option key={col.accessorKey as string} value={col.accessorKey as string}>
                {col.accessorKey as string}
              </option>
            )
          ))}
        </select>
      </div>
    </div>
  );
}

export default function DataTable<T>({ 
  data, 
  meta, 
  query,
  columns: userColumns, 
  loading,
  rowsPerPage,
  currentPage,
  onPageChange,
  onQueryClear,
  onSearch,
  onBulkAction,
  onCellEdit,
  onSearchFieldChange,
  onSearchValueChange,
  onInsertRow,
}: DataTableProps<T>) {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [searchInfo, setSearchInfo] = useState<{ field: string; value: string; }>();
  const [activeCell, setActiveCell] = useState<{rowIndex: number, columnIndex: number} | null>(null);

  // Reset selection when page changes or data changes
  useEffect(() => {
    setSelectedRows(new Set());
  }, [meta?.offset, data]);

  // Add this effect after the existing useEffect for resetting selection
  useEffect(() => {
    // Reset active cell when data changes (page change, table switch, etc)
    setActiveCell(null);
  }, [data, meta?.offset]);

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async (complete: Function) => {
    if (!onBulkAction) return;
    
    const selectedData = Array.from(selectedRows).map(index => data[index]);
    await onBulkAction('delete', selectedData);
    
    setSelectedRows(new Set());
    complete();
    setShowDeleteConfirm(false);
  };

  const handleNextCell = (currentRowIndex: number, currentColumnIndex: number) => {
    let nextColumnIndex = currentColumnIndex + 1;
    let nextRowIndex = currentRowIndex;

    // If we're at the last column, go to next row
    if (nextColumnIndex >= tableColumns.length) {
      nextRowIndex = currentRowIndex + 1;
      // Skip to first editable column (index 2, after checkbox and id)
      nextColumnIndex = 2;
      
      // If we're at the last row, do nothing
      if (nextRowIndex >= data.length) {
        return;
      }
    }

    // Skip first two columns (checkbox and id) if somehow we're there
    if (nextColumnIndex < 2) {
      nextColumnIndex = 2;
    }

    setActiveCell({ rowIndex: nextRowIndex, columnIndex: nextColumnIndex });
  };

  const handleCellActivate = (cell: {rowIndex: number, columnIndex: number} | null) => {
    setActiveCell(cell);
  };

  // Update the column mapping to include indices
  const tableColumns = [];
  // Whether or not we have an ID field
  // Optimistically assume if we have no rows
  const hasId = data.length === 0 || (data[0] as {[key: string]: string})?.id !== void 0;

  if (hasId) {
    tableColumns.push({
      id: 'select',
      size: 28,
      header: ({ table }: any) => (
        <div 
          className="checkbox"
          onClick={(e) => {
            e.stopPropagation();
            // Clear any active cell when selecting all rows
            setActiveCell(null);
            const newChecked = selectedRows.size !== data.length;
            if (newChecked) {
              setSelectedRows(new Set(data.map((_, i) => i)));
            } else {
              setSelectedRows(new Set());
            }
          }}
        >
          <input
            type="checkbox"
            checked={data.length > 0 && selectedRows.size === data.length}
            disabled={data.length === 0}
            onChange={() => {}}
          />
          <span className="checkmark">
            <Check size={12} />
          </span>
        </div>
      ),
      cell: ({ row }: any) => (
        <div 
          className="checkbox"
          onClick={(e) => {
            e.stopPropagation();
            // Clear any active cell when selecting rows
            setActiveCell(null);
            const newSelected = new Set(selectedRows);
            if (!selectedRows.has(row.index)) {
              newSelected.add(row.index);
            } else {
              newSelected.delete(row.index);
            }
            setSelectedRows(newSelected);
          }}
        >
          <input
            type="checkbox"
            checked={selectedRows.has(row.index)}
            onChange={() => {}}
          />
          <span className="checkmark">
            <Check size={12} />
          </span>
        </div>
      )
    });
  }

  if (hasId) {
    tableColumns.push(
      {
        // ...userColumns[0], do not need this, we're setting manually
        id: 'id',
        accessorKey: 'id',
        header: ({ table }: any) => (
          <span data-index="true">id</span>
        ),
        cell: ({ row, column }: any) => (
          <DataTableCell
            isIndex={true}
            value={row.getValue(column.id)}
            readonly={true}
            rowIndex={row.index}
            columnIndex={1}
            isActive={activeCell?.rowIndex === row.index && activeCell?.columnIndex === 1}
            onActivate={handleCellActivate}
          />
        )
      }
    );
  }

  const notFoundColumns: ColumnDef<T>[] = [];
  const validColumnLookup: {[key: string]: boolean} = {};
  if (data.length > 0) {
    const entry = data[0] as {[key: string]: string};
    const keys = Object.keys(entry);
    notFoundColumns.push(...(
      keys
        .filter(key => !userColumns.some(col => col.id === key))
        .map(key => ({ id: key, accessorKey: key }))
    ));
    for (const key of keys) {
      validColumnLookup[key] = true;
    };
  } else {
    for (const userColumn of userColumns) {
      validColumnLookup[userColumn.id || ''] = true;
    }
  }

  tableColumns.push(
    ...userColumns.concat(notFoundColumns)
      .filter(col => col.id !== 'id')
      .filter(col => validColumnLookup[col.id || ''])
      .map((col, idx) => ({
        ...col,
        cell: ({ row, column }: any) => (
          <DataTableCell 
            value={row.getValue(column.id)}
            onChange={(newValue) => {
              if (onCellEdit) {
                onCellEdit({
                  rowIndex: row.index,
                  columnId: column.id,
                  value: newValue
                });
              }
            }}
            readonly={!hasId}
            rowIndex={row.index}
            columnIndex={idx + 2}
            isActive={activeCell?.rowIndex === row.index && activeCell?.columnIndex === (idx + 2)}
            onActivate={handleCellActivate}
            onNextCell={handleNextCell}
          />
        )
      })
    ),
  );

  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  });

  return (
    <div data-component="DataTable">
      <div className="table-container">
        <div className="table-actions">
          <div className="actions-left">
            <button 
              className={`delete-button ${selectedRows.size === 0 ? 'disabled' : ''}`}
              onClick={handleDeleteClick}
              disabled={selectedRows.size === 0}
            >
              {selectedRows.size === 0 
                ? 'No rows selected'
                : (<>
                    <Trash2 size={14} />
                    Delete {selectedRows.size} row{selectedRows.size !== 1 ? 's' : ''}
                  </>)}
            </button>
            {onInsertRow && (
              <button 
                className="insert-row-button"
                onClick={onInsertRow}
              >
                <Plus size={14} />
                Insert row
              </button>
            )}
          </div>
          <div className="actions-spacer" />
          <div className="actions-right">
            {onSearch && !query && (
              <TableSearch 
                columns={userColumns} 
                onSearch={onSearch}
                onSearchInfoChange={setSearchInfo}
                loading={loading}
              />
            )}
            {query && (
              <div className="query-container" data-tooltip={query}>
                <span className="query-icon">
                  <Code size={14} />
                </span>
                <div className="query-text">
                  {query}
                </div>
                <a 
                  className="clear-query"
                  onClick={onQueryClear}
                >
                  <X size={14} />
                </a>
              </div>
            )}
            {meta && onPageChange && (
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(meta.total / rowsPerPage)}
                meta={meta}
                onPageChange={onPageChange}
                searchInfo={searchInfo}
              />
            )}
          </div>
        </div>
        <div className="table-scroll">
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={tableColumns.length}>
                    <div className="empty-state loading">
                      <Loader size={14} />
                      <p>Loading...</p>
                    </div>
                  </td>
                </tr>
              )}
              {!loading && (
                data.length > 0 ? (
                  table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={tableColumns.length}>
                      <div className="empty-state">
                        <p>No rows available.</p>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showDeleteConfirm && (
        <AlertModal
          message={`Are you sure you want to delete ${selectedRows.size} row${selectedRows.size !== 1 ? 's' : ''}?`}
          color="red"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}
    </div>
  );
}
