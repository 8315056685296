import { LoaderFunctionArgs } from "react-router-dom";
import { AgentSchema } from "../../utils/types";
import API from "../../utils/api";

export default function AgentsPageLoader() {
  return async ({ params } : LoaderFunctionArgs) => {
    const agentResponse = await API.get('v1/agents/retrieve', { id: params.agent_id });
    const agentData = agentResponse as AgentSchema;
    return agentData;
  };
};